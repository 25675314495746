import React from 'react'
import styles from '../styles/app.scss'
import {bindActionCreators} from 'redux'
import {getNewCondolences} from '../actions'
import { connect } from 'react-redux'
import NewCondolence from './NewCondolences'

class Sidebar extends React.PureComponent {
    render() {
        return (
            <div className={[styles["sidebar"], styles["uk-width-1-1"], styles["uk-width-1-3@m"]].join(' ')}>
                <aside>
                    <div className={[styles["sidebar-item"], styles["uk-padding"]].join(' ')}>
                        <h2>neueste Kondolenzeinträge</h2>
                        <div>
                            {this.props.newCondolences.loading ? (
                                <div className={styles["loading"]}>
                                    <div data-uk-spinner={''}/>
                                    <div>Lade Daten...</div>
                                </div>
                            ) : (
                                <ul className={[styles["uk-list"], styles["uk-list-divider"]].join(' ')}>
                                    {typeof this.props.newCondolences.condolences !== 'undefined' && this.props.newCondolences.condolences.map((condolence, key) => {
                                        return (
                                            <NewCondolence condolence={condolence} key={key}/>
                                        )
                                    })}
                                </ul>
                            )}
                        </div>
                    </div>
                </aside>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        newCondolences: state.newCondolences,
    }
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        getNewCondolences: getNewCondolences,
    }, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(Sidebar)