import React from 'react'
import { client } from '../../helpers/config'

const Header = () => {
// using variables in import statement does not work yet
  const BrandedHeader = (client === 'pnp') ? React.lazy(() => import('./templateParts/Header/pnp')) : React.lazy(() => import('./templateParts/Header/dk'))

  return (
      <React.Suspense fallback={<div>Lade Daten...</div>}>
        <BrandedHeader/>
      </React.Suspense>
  )
}

export default Header