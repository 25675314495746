import React from 'react'
import styles from '../../styles/app.scss'
import Fade from '../../helpers/fade'
import moment from 'moment/moment'
import Paginator from '../../helpers/paginator'
import { bindActionCreators } from 'redux'
import { getDeathNoticeCondolences } from '../../actions'
import { connect } from 'react-redux'
import Api from '../../helpers/api'
import NewCondolenceForm from './NewCondolenceForm'
import { confirmAlert } from 'react-confirm-alert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styler from '../../helpers/styler'
import EditCondolenceForm from './EditCondolenceForm'
import history from '../../helpers/history'

const itemsPerPage = 5

/**
 * Condolence Book of Death Notice
 *
 * @class DeathNotice
 * @author Alexander Jungwirth
 * @version 1.0.0.0
 */
class CondolenceBook extends React.Component {

  constructor (props) {
    super(props)

    this.state = {
      condolenceId: 0,
      condolenceContent: '',
      showEntryForm: false,
      newCondolenceSent: false,
      newCondolenceLoading: false,
      condolenceContentLength: 0,
      bee: '',
      editCondolenceId: null,
      showEditForm: false,
      hasSubscription: null,
      editFormMessage: null,
    }

  }

  /**
   * change state when url changed
   * @param nextProps
   * @param prevState
   * @returns {*}
   */
  static getDerivedStateFromProps (nextProps, prevState) {
    if (nextProps.condolenceId !== prevState.condolenceId) {
      return { condolenceId: nextProps.condolenceId }
    } else {
      return null
    }
  }

  /**
   * get details when state changed
   *
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevState.condolenceId !== this.state.condolenceId) {
      this.props.getDeathNoticeCondolences({ id: this.state.condolenceId, page: 1 })
      Api.post('count-condolences', {
        condolenceId: this.state.condolenceId
      }).then((response) => {
        this.setState({ condolenceCount: response.data.totalItems })
      })
    }
  }

  /**
   * componentDidMount
   */
  componentDidMount () {
    this.props.getDeathNoticeCondolences({ id: this.state.condolenceId, page: 1 })

    Api.post('count-condolences', {
      condolenceId: this.state.condolenceId
    }).then((response) => {
      this.setState({ condolenceCount: response.data.totalItems })
    })

    this.checkSubscription()
  }

  checkSubscription = () => {
    Api.post('subscription', {
      user_id: this.props.user.id,
      notice_id: this.state.condolenceId
    }).then((response) => {
      this.setState({ hasSubscription: response.data.subscribed })

    })
  }

  /**
   * load condolences by page
   *
   * @param pageNumber
   */
  loadCondolencesPage = (pageNumber) => {
    this.props.getDeathNoticeCondolences({
      id: this.state.condolenceId,
      page: pageNumber
    })
  }

  /**
   * show the add a condolence form
   */
  showEntryForm () {
    this.setState({ showEntryForm: true })
  }

  /**
   * update the form
   */
  updateForm () {
    this.forceUpdate()
  }

  deleteCondolence = (condolenceId) => {
    confirmAlert({
      title: 'Eintrag löschen',
      message: 'Sind Sie sicher?',
      buttons: [
        {
          label: 'Ja',
          onClick: () => {
            Api.post('delete-condolence', {
              condolenceId: condolenceId
            }).then((response) => {
              if (response.data.success === true) {
                this.props.getDeathNoticeCondolences({ id: this.state.condolenceId, page: 1 })
              } else {

              }
            })
          }
        },
        {
          label: 'Nein',
        }
      ]
    })
  }

  editCondolence = (condolenceId) => {
    this.setState({ showEditForm: true, editCondolenceId: condolenceId })
  }

  rerender = (message) => {
    this.setState({ showEditForm: false, editCondolenceId: null, editFormMessage: message },
        this.props.getDeathNoticeCondolences({ id: this.state.condolenceId, page: 1 })
    )
  }

  unsubscribe = () => {
    Api.post('unsubscribe', {
      'user_id': this.props.user.id,
      'notice_id': this.state.condolenceId
    }).then(this.checkSubscription)
  }

  subscribe = () => {
    if (this.props.user.id === null) {
      confirmAlert({
        title: 'Login benötigt',
        message: 'Um Kondolenzbücher zu abonnieren, müssen Sie angemeldet sein.',
        buttons: [
          {
            label: 'zum Login',
            onClick: () => {
              history.push('/login')
            }
          },
          {
            label: 'Abbrechen',
          }
        ]
      })
    } else {
      Api.post('subscribe', {
        'user_id': this.props.user.id,
        'notice_id': this.state.condolenceId
      }).then(this.checkSubscription)
    }

  }

  subscriptionButton = () => {
    if (this.state.hasSubscription === null) return
    if (this.state.hasSubscription) {
      return (<button className={styler(['uk-button', 'uk-button-unsubscribe'])} onClick={this.unsubscribe}><FontAwesomeIcon icon={'rss'} className={styler(['uk-margin-small-right'])}/>abbestellen</button>)
    } else {
      return (<button className={styler(['uk-button', 'uk-button-subscribe'])} onClick={this.subscribe}><FontAwesomeIcon icon={'rss'} className={styler(['uk-margin-small-right'])}/>abonnieren</button>)
    }
  }

  render () {

    return (<div className={[styles['condolences'], styles['uk-margin-small-top']].join(' ')}>
      <div className={styles['uk-padding-small']}>
        <div className={styles['divider']}>
          <h2>Kondolenzbuch</h2>
        </div>

        <div className={styler(['uk-text-center', 'uk-margin-medium-bottom'])}>
          {this.subscriptionButton()}
        </div>

        {typeof this.props.deathNoticeCondolences.condolences !== 'undefined' ? (
            <div className={styles['uk-margin-small-top']}>
              <div className={[styles['uk-text-center'], styles['uk-margin-medium-bottom']].join(' ')}>
                <div className={styles['uk-margin-small-bottom']}>
                  {(this.state.condolenceCount === 0) ? (
                      <>
                        es wurden noch keine Einträge verfasst
                      </>
                  ) : (
                      <>
                        {this.state.condolenceCount}
                        {(this.state.condolenceCount === 1) ? (
                            <span> Eintrag</span>
                        ) : (
                            <span> Einträge</span>
                        )}
                      </>
                  )
                  }

                </div>

                {this.state.editFormMessage !== null &&
                <div className={[styles['uk-margin-meduim-top'], styles['uk-margin-medium-bottom']].join(' ')}>{this.state.editFormMessage}</div>
                }

                <NewCondolenceForm condolenceId={this.state.condolenceId}/>
              </div>

              {typeof this.props.deathNoticeCondolences.condolences.data !== 'undefined' && this.props.deathNoticeCondolences.condolences.data.map((condolence, key) => {
                const condolenceText = (condolence.text.indexOf('<p>') > -1) ? condolence.text : condolence.text.replace(/\n/g, '<br/>')

                return (
                    <Fade key={key}>
                      {(this.state.showEditForm && this.state.editCondolenceId === condolence.id) ? (
                          <EditCondolenceForm condolence={condolence} callback={this.rerender}/>
                      ) : (
                          <>

                            <div className={styler(['condolence', 'uk-margin-medium-bottom', 'uk-padding', 'uk-position-relative'])}>
                              <div className={[styles['uk-flex'], styles['uk-flex-between'], styles['uk-margin-medium-bottom']].join(' ')}>
                                <div className={[styles['uk-text-bold'], styles['uk-text-serif']].join(' ')}>
                                  {condolence.user_name.length > 0 &&
                                  <>von {condolence.user_name}</>
                                  }
                                </div>
                                <div className={[styles['uk-text-italic'], styles['nowrap']].join(' ')}>{moment(condolence.create_time).format('L')}</div>
                              </div>
                              {condolence.user_id === this.props.user.id &&
                              <div className={styler(["uk-text-right", "user-buttons"])}>
                                <FontAwesomeIcon icon={"edit"} onClick={() => this.editCondolence(condolence.id)} title={"Eintrag bearbeiten"} className={styler(["uk-margin-small-right"])}/>
                                <FontAwesomeIcon icon={"trash"} onClick={() => this.deleteCondolence(condolence.id)} title={"Eintrag löschen"}/>
                              </div>
                              }
                              <div className={styles['uk-margin-small-top']}>
                                <span dangerouslySetInnerHTML={{ __html: condolenceText }}/>
                              </div>
                              <div className={styler(['uk-text-small', 'report-button'])}
                                   data-uk-tooltip={'title: Melden Sie diesen Kondolenzeintrag als unpassend; pos: top-left;'}
                                   onClick={() => history.push('/kondolenz-melden/' + condolence.id)}>
                                <FontAwesomeIcon icon={'flag'}/> melden
                              </div>
                            </div>
                          </>
                      )}
                    </Fade>
                )
              })}


            </div>
        ) : (
            <div className={styles['loading']}>
              <div data-uk-spinner={''}/>
              <div>Lade Daten...</div>
            </div>
        )}


        {typeof this.state.condolenceCount !== 'undefined' && this.state.condolenceCount != null &&
        <Paginator
            itemsPerPage={itemsPerPage}
            totalItems={this.state.condolenceCount}
            onPageChange={this.loadCondolencesPage}
            activePage={1}
        />
        }

      </div>
    </div>)
  }
}

function mapStateToProps (state) {
  return {
    user: state.user,
    deathNoticeCondolences: state.deathNoticeCondolences,
  }
}

function matchDispatchToProps (dispatch) {
  return bindActionCreators({
    getDeathNoticeCondolences: getDeathNoticeCondolences,
  }, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(CondolenceBook)
