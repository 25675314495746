import Api from '../helpers/api'
import { remove } from './loginAction'
import history from '../helpers/history'

export const logoutAction = () => {
    return (dispatch) => {
        dispatch({ type: 'DO_LOGOUT', payload: { loading: true } })
        history.push('/')
        Api.post('sso/logout', {
            login: localStorage.getItem('login'),
            hash: localStorage.getItem('hash')
        }).then(() => {
            remove()
            dispatch({
                type: 'LOGOUT', payload: {
                    loading: false,
                }
            })

        })
    }
}
