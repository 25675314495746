import React from 'react';
import ReactDOM from 'react-dom';
import 'babel-polyfill';
import App from './containers/App';
import * as serviceWorker from './serviceWorker';
import './helpers/icons';
import {createStore, applyMiddleware, compose} from 'redux'
import allReducers from './reducers'
import moment from 'moment'
import 'moment/locale/de'
import TagManager from 'react-gtm-module'
import {Provider} from 'react-redux'
import thunk from 'redux-thunk'
import {Router} from 'react-router-dom'
import history from './helpers/history'
import Auth from './helpers/auth';
import IVWPixel from './helpers/ivw';
import HttpsRedirect from 'react-https-redirect';
import icons from './helpers/icons'

window.UIkit = require('uikit');

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(allReducers, composeEnhancers(
    applyMiddleware(thunk)
));

moment.locale('de');

Auth.initialize();


const Index = () => {
    return (
        <Provider store={store}>
            <HttpsRedirect>
                <Router history={history}>
                    <App/>
                </Router>
            </HttpsRedirect>
        </Provider>
    )
}

ReactDOM.render(<Index/>, document.getElementById('trauerportal'));

/** TODO: enable serviceWoker */
serviceWorker.unregister();
