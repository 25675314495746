export default function loginReducer(state = {}, action) {
    switch (action.type) {
        case 'DO_LOGIN':
            return action.payload;
        case 'LOGIN':
            return action.payload;
        case 'DO_LOGOUT':
            return action.payload;
        case 'LOGOUT':
            return action.payload;
        default:
            return state;
    }
}
