import React from 'react'
import { client } from '../../helpers/config'
import styler from '../../helpers/styler'

/**
 * show footer tag

 */
const LoginForm = (props) => {
    // using variables in import statement does not work yet
    const BrandedLoginForm = (client === 'pnp') ? React.lazy(() => import('../Layout/templateParts/LoginForm/pnp')) : React.lazy(() => import('../Layout/templateParts/LoginForm/dk'))

    return (
        <React.Suspense fallback={<div className={styler(['uk-position-relative'])} style={{ height: '20rem' }}>
            <div className={styler(['uk-position-center', 'loading'])}>
                <div data-uk-spinner={''}/>
                <div>Lade Daten...</div>
            </div>
        </div>}>
            <BrandedLoginForm {...props}/>
        </React.Suspense>
    )
}

export default LoginForm
