import {isMobile} from 'react-device-detect';

const ivwCode = (isMobile) ? '203trauerportal_mob' : '203trauerportal';
const site = (isMobile) ? 'mobdokur' : 'dokurier';
const sv = (isMobile) ? 'mo' : 'in';

const IVWPixel = {
    initialize: () => {
        const ivwScript = `if (window.navigator.userAgent.indexOf('Simply') === -1) {
                    var iam_data = {
                        st: '${site}',
                        cp: '${ivwCode}',
                        sv: '${sv}'
                    };
                }`;
        const script = document.createElement('script');
        script.innerHTML = ivwScript;
        document.head.appendChild(script);
    },
    pageLoad: () => {
        // const script = document.createElement('script');
        // script.innerHTML = 'iom.c(iam_data,1)';
        // document.body.appendChild(script);
    }
};


export default IVWPixel;






