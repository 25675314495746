import React from 'react'
import {Switch, Route} from 'react-router-dom'
import DeathNoticeDetails from '../DeathNoticeDetails'
import DeathNoticeOverview from '../DeathNoticeOverview'
import Home from '../Home'
import Login from '../Auth/Login'
import Search from '../Search/Search'
import Register from '../Auth/Register'
import PageNotFound from '../../errors/PageNotFound'
import Profile from "../Auth/Profile"
import ReportCondolence from "../CondolenceBook/ReportConcolence"
import SendValidationLink from '../Auth/SendValidationLink'
import styler from "../../helpers/styler"

/**
 * class Main
 * + routing
 *
 * @author Alexander Jungwirth
 * @version 1.0.0.2
 */
const Main = () => {
    return (
        <main className={styler(["uk-flex-grow-1", "uk-height-1-1", "uk-min-height-1-1"])} id="main">
            <Switch>
                <Route exact path="/" component={Home}/>
                <Route exact path="/traueranzeige/:id" component={DeathNoticeDetails}/>
                <Route exact path="/traueranzeige/:name/:id" component={DeathNoticeDetails} />
                <Route exact path="/traueranzeige-search/:name/:id" component={DeathNoticeDetails} />
                <Route exact path="/search" component={Search}/>
                <Route exact path="/login" component={Login}/>
                <Route exact path="/profile" component={Profile}/>
                <Route exact path="/register"  component={() => {
                    window.open('https://id.donaukurier.de/registrierung/')
                    return null
                }}/>
                <Route exact path="/validate-email" component={SendValidationLink}/>
                <Route exact path="/anzeigen/:edition?" component={DeathNoticeOverview}/>
                <Route exact path="/kondolenz-melden/:id" component={ReportCondolence}/>
                <Route path="/not-found" status={410} component={PageNotFound} />
                <Route path="*" status={404} component={PageNotFound} />
            </Switch>
        </main>
    )
}

export default Main


