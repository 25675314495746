import React from 'react'
import {Link} from 'react-router-dom'
import Fade from '../helpers/fade'
import Head from './Layout/Head'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {getDeathNotices, getNewCondolences, getNewCommemorations} from '../actions'
import SearchBox from './Search/SearchBox'
import Paginator from '../helpers/paginator'
import ApiError from '../errors/ApiError'
import Api from '../helpers/api'
import moment from 'moment'
import styles from '../styles/app.scss'
import DeathNotice from './DeathNotice'
import NewCondolence from './NewCondolences'
import NewCommemorations from './NewCommemorations'
import IVWPixel from '../helpers/ivw'
import {initialize} from "../helpers/agf";

/**
 * Home component
 * includes: search for death notices, current death notices, link to adviser and business directory, current condolence notices and yearly commemorations
 *
 * @class Home
 * @author Alexander Jungwirth
 */
class Home extends React.Component {

    state = {};

    componentDidMount() {
        Api.post('count-commemoration', {
            date: moment().format('YYYY-MM-DD')
        }).then((response) => {
            this.setState({...this.state, commemorationCount: response.data.count});
        });

        this.props.getDeathNotices();
        this.props.getNewCondolences();
        this.props.getNewCommemorations({page: 1});

        IVWPixel.pageLoad();
        initialize('homepage', 'homepage');
    }


    /**
     * load commemorations by page
     *
     * @param pageNumber
     */
    loadCommemorations(pageNumber) {
        this.props.getNewCommemorations({
            page: pageNumber
        });
    }

    /**
     * grid of death notices
     *
     * @returns {*}
     */
    deathNotices() {
        if (this.props.deathNotices.loading) {
            return (
                <div className={styles["uk-position-relative"]} style={{height: '20rem'}}>
                    <div className={[styles["uk-position-center"], styles["loading"]].join(' ')}>
                        <div data-uk-spinner={''}/>
                        <div>Lade Daten...</div>
                    </div>
                </div>
            )
        } else {
            if (typeof this.props.deathNotices.notices === 'undefined') {
                return (
                    <ApiError/>
                )
            } else {
                if (!this.props.deathNotices.success) return <ApiError/>
                const deathNotices = [];
                this.props.deathNotices.notices.data.forEach((notice, key) => {
                    deathNotices.push(<DeathNotice notice={notice} key={key}/>);
                });
                return <div className={styles["uk-grid-match"]} data-uk-grid>{deathNotices}</div>;
            }
        }

    }

    /**
     * list of new condolences
     *
     * @returns {*}
     */
    newCondolences() {
        if (this.props.newCondolences.loading || typeof this.props.newCondolences.condolences === 'undefined') {
            return (
                <div className={styles["uk-position-relative"]} style={{height: '20rem'}}>
                    <div className={[styles["uk-position-center"], styles["loading"]].join(' ')}>
                        <div data-uk-spinner={''}/>
                        <div>Lade Daten...</div>
                    </div>
                </div>)
        } else {
            if (!this.props.newCondolences.success) return <ApiError/>
            return (
                <ul className={[styles["uk-list"], styles["uk-list-divider"]].join(' ')}>
                    {this.props.newCondolences.condolences.map((condolence, key) => {
                        return <NewCondolence condolence={condolence} key={key}/>
                    })}
                </ul>
            )
        }
    }

    /**
     * list of todays commemorations
     *
     * @returns {*}
     */
    todaysCommemorations() {
        if (this.props.newCommemorations.loading || typeof this.props.newCommemorations.commemorations === 'undefined') {
            return (<div className={styles["uk-position-relative"]} style={{height: '360px', margin: '20px 0'}}>
                <div className={[styles["uk-position-center"], styles["loading"]].join(' ')}>
                    <div data-uk-spinner={''}/>
                    <div>Lade Daten...</div>
                </div>
            </div>)
        } else {
            if (!this.props.newCommemorations.success) return <ApiError/>
            return (this.props.newCommemorations.commemorations.data.length > 0) ?
                <ul className={[styles["uk-list"], styles["uk-list-divider"]].join(' ')}>
                    {this.props.newCommemorations.commemorations.data.map((commemoration, key) => {
                        return <NewCommemorations commemoration={commemoration} key={key}/>
                    })}
                </ul>
                : <div className={[styles["uk-text-center"], styles["uk-margin-large-top"]].join(' ')}>keine Einträge gefunden</div>
        }
    }


    /**
     * render method
     *
     * @returns {*}
     */
    render() {
        const canonicalUrl = window.location.href;

        return (
            <Fade>
                <Head title="DONAUKURIER Trauerportal" canonicalUrl={canonicalUrl} />
                <div className={styles.home}>
                    <div className={[styles["banner-search"], styles["uk-padding-large"]].join(' ')} data-uk-parallax="bgy: -100">
                        <SearchBox/>
                    </div>
                    <div className={styles["current-death-notices"]}>
                        <div className={[styles["uk-container"], styles["uk-padding"]].join(' ')}>

                            <div className={styles["divider"]}>
                                <h2>Aktuelle Traueranzeigen</h2>
                            </div>
                            <div className={styles["uk-margin-large-top"]}>
                                {this.deathNotices()}
                            </div>
                            <div className={[styles["uk-text-center"], styles["uk-text-italic"], styles["uk-text-serif"], styles["uk-margin-medium-top"]].join(' ')}>
                                <Link to="/anzeigen">alle Traueranzeigen ansehen</Link>
                            </div>
                        </div>
                    </div>

                    <div className={styles["current-stuff"]}>
                        <div className={[styles["uk-container"], styles["uk-padding"]].join(' ')}>
                            <div data-uk-grid>
                                <div className={[styles["uk-width-1-1"], styles["uk-width-1-2@m"], styles["uk-margin-medium-bottom"], styles["condolences"]].join(' ')}>
                                    <div className={styles["divider"]}>
                                        <h2>neueste Kondolenzeinträge</h2>
                                    </div>
                                    {this.newCondolences()}
                                </div>

                                <div className={[styles["uk-width-1-1"], styles["uk-width-1-2@m"], styles["uk-margin-medium-bottom"], styles["commemorations"]].join(' ')}>
                                    <div className={styles["divider"]}>
                                        <h2>heutige Jahresgedenken</h2>
                                    </div>
                                    {this.todaysCommemorations()}
                                    <div className={[styles["uk-text-right"], styles["uk-margin-small-top"]].join(' ')}>
                                        {typeof this.state.commemorationCount !== 'undefined' &&
                                        <Paginator itemsPerPage={5} totalItems={this.state.commemorationCount} onPageChange={this.loadCommemorations.bind(this)} pageRangeDisplayed={3}/>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fade>

        )
    }
}

function mapStateToProps(state) {
    return {
        deathNotices: state.deathNotices,
        newCondolences: state.newCondolences,
        newCommemorations: state.newCommemorations
    }
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        getDeathNotices: getDeathNotices,
        getNewCondolences: getNewCondolences,
        getNewCommemorations: getNewCommemorations,
    }, dispatch)
}


export default connect(mapStateToProps, matchDispatchToProps)(Home);
