import React from 'react'
import { bindActionCreators } from 'redux'
import { getDeathNoticeCondolences } from '../../actions'
import { connect } from 'react-redux'
import Api from '../../helpers/api'
import styles from '../../styles/app.scss'
import Fade from '../../helpers/fade'
import LoginForm from '../Auth/LoginForm'
import RichTextEditor from 'react-rte'
import styler from '../../helpers/styler'
import {client} from '../../helpers/config'

/** count of chars a guest can write */
const maxCharsCondolence = 500

/** tooltip of advantages */
const advantages = '<strong>Ihre Vorteile:</strong>' +
    '<ul>' +
    '<li>unbegrenzte Länge der Kondolenzen</li>' +
    '<li>komfortabler Editor für Ihre Einträge</li>' +
    '<li>bearbeiten und löschen Ihrer Einträge</li>' +
    '<li>Kondolenzbücher abonnieren</li>' +
    '</ul>'

/** rte toolbar */
const toolbarConfig = {
  display: ['HISTORY_BUTTONS', 'INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'BLOCK_TYPE_DROPDOWN'],
  INLINE_STYLE_BUTTONS: [
    { label: 'fett', style: 'BOLD', },
    { label: 'kursiv', style: 'ITALIC' },
    { label: 'unterstrichen', style: 'UNDERLINE' }
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: 'normal', style: 'unstyled' },
    { label: 'groß', style: 'header-two' },
    { label: 'mittel', style: 'header-three' }
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: 'Liste', style: 'unordered-list-item' },
    { label: 'Zahlenliste', style: 'ordered-list-item' }
  ]
}

/**
 * Condolence Book Form for user to write a new condolence
 * - if logged in: RichTextEditor, no max chars, edit and delete,
 */
class NewCondolenceForm extends React.Component {

  state = {
    showEntryForm: false,
    condolenceTextareaContent: '',
    condolenceRichTextContent: RichTextEditor.createEmptyValue(),
    condolenceContentLength: 0,
    showLoginForm: false,
    showEditor: true,
    user_name: (this.props.user && this.props.user.lastname) ? (this.props.user.firstname + ' ' + this.props.user.lastname.substr(0, 1) + '.') : '',
    validated: true
  }

  /**
   * change state when user logs in
   * @param nextProps
   * @param prevState
   * @returns {*}
   */
  static getDerivedStateFromProps (nextProps, prevState) {
    if ((nextProps.user.hasOwnProperty('id') && nextProps.user.id !== null)) {
      return {
        showLoginForm: false,
        showEditor: true
      }
    } else {
      return null
    }
  }

  /**
   * input change handler
   *
   * @param event
   */
  handleInputChange = (event) => {
    const value = event.target.value
    const length = value.length

    if (!this.props.user.hasOwnProperty('id') || this.props.user.id === null) {
      if (maxCharsCondolence - length < 0) {
        return
      }
    }

    this.setState({
      condolenceTextareaContent: value,
      condolenceContentLength: length,
      condolenceRichTextContent: RichTextEditor.createValueFromString(value, 'html')
    })
  }

  handleUserChange = (event) => {
    this.setState({ user_name: event.target.value })
  }
  onChange = (condolenceRichTextContent) => {
    this.setState({ condolenceRichTextContent })
    if (this.props.onChange) {
      this.props.onChange(
          condolenceRichTextContent.toString('html')
      )
    }
  }

  /**
   * change handler for bee (honeypot)
   *
   * @param event
   */
  handleBeeChange = (event) => {
    const value = event.target.value

    this.setState({
      bee: value,
    })
  }

  /**
   * show login form
   */
  showLoginForm () {
    this.setState({ showLoginForm: true })
    this.forceUpdate()
  }

  /**
   * add condolence
   *
   * @param event
   */
  postMessage = (event) => {
    event.preventDefault()
    if (this.state.user_name.length < 3 && this.state.condolenceTextareaContent.length < 1) {
      this.setState({ validated: false })
      return
    } else {
      this.setState({ validated: true })
    }

    if (this.props.user.hasOwnProperty('id') && this.props.user.id !== null) {
      if (this.state.condolenceRichTextContent.toString('html').replace(/(<([^>]+)>)/ig, '').length < 1) {
        this.setState({ validated: false })
        return false
      }
    } else {
      if (this.state.condolenceTextareaContent.length < 1) {
        this.setState({ validated: false })
        return false
      }
    }

    Api.post('create-condolence', {
      condolenceId: this.props.condolenceId,
      userId: this.props.user.id || 'unknown',
      userName: this.state.user_name || 'Gast',
      userEmail: this.props.user.email || ((client === 'pnp') ? 'unknown@trauer.pnp.de' : 'unknown@trauer.donaukurier.de'),
      content: (this.props.user.hasOwnProperty('id') && this.props.user.id !== null) ? this.state.condolenceRichTextContent.toString('html') : this.state.condolenceTextareaContent, //this.state.condolenceContent,
      source: (client === 'pnp') ? 'trauer.pnp.de' : 'trauer.donaukurier.de',
      status: 1
    }).then((response) => {
      if (response.data.success) {
        this.setState({
          newCondolenceSent: true,
          newCondolenceLoading: false,
        })
      } else {
        console.error(response.data.message)
      }
    })

  }

  /**
   * depending on user logged in show send button or teaser for login box
   *
   * @returns {*}
   */
  sendButtons = () => {
    /** not logged in */
    if (!this.props.user.hasOwnProperty('id') || this.props.user.id === null) {
      return (
          <div>
            <button className={styler(['uk-button', 'uk-button-default', 'send-button', 'uk-margin-medium-right', 'uk-margin-remove-right@s'])}
                    onClick={this.postMessage}
                    disabled={this.state.newCondolenceLoading}>
              {(this.state.newCondolenceLoading) ? (
                  <Fade>
                    <div data-uk-spinner={'ratio:0.8'}/>
                  </Fade>
              ) : (
                  <span>als Gast absenden</span>
              )}
            </button>
            <button className={[styles['uk-button'], styles['uk-button-primary']].join(' ')}
                    onClick={(event) => {
                      event.preventDefault()
                      this.setState({
                        showLoginForm: true,
                        showEditor: false
                      })
                    }}
                    data-uk-tooltip={'title: ' + advantages + '; pos: top-left;'}>
              einloggen und Vorteile genießen
            </button>
          </div>
      )
    } else {
      return (
          <button className={[styles['uk-button'], styles['uk-button-primary'], styles['send-button']].join(' ')}
                  onClick={this.postMessage}
                  disabled={this.state.newCondolenceLoading}>
            {(this.state.newCondolenceLoading) ? (
                <Fade>
                  <div data-uk-spinner={'ratio:0.8'}/>
                </Fade>
            ) : (
                <span>absenden</span>

            )}
          </button>
      )
    }

  }

  render () {
    return (
        <div>
          {(!this.state.showEntryForm) ? (
              <div>
                <button className={[styles['uk-button'], styles['uk-button-default']].join(' ')} onClick={() => this.setState({ showEntryForm: !this.state.showEntryForm })}>
                  neuen Eintrag erstellen
                </button>
              </div>
          ) : (
              <span>
            {(this.state.newCondolenceSent) ? (
                <Fade>
                  <div className={[styles['uk-margin-meduim-top'], styles['uk-margin-medium-bottom']].join(' ')}>
                    Vielen Dank für Ihren Kondolenzeintrag. Wir bitten um Ihr Verständnis, dass dieser zunächst geprüft und von uns freigegeben werden
                    muss.
                  </div>
                </Fade>
            ) : (
                <Fade>
                  <div className={[styles['uk-margin-meduim-top'], styles['uk-margin-medium-bottom']].join(' ')}>
                    <h3>neuen Kondolenzeintrag erstellen</h3>
                    <form className={styles['uk-form-horizontal']}>
                      <div className={[styles['uk-margin-top'], styles['uk-margin-small-bottom']].join(' ')}>

                        {this.state.showEditor &&
                        <>
                          {(!this.props.user.hasOwnProperty('id') || this.props.user.id === null) ? (
                              <>
                                <div className={styler(['uk-margin', 'uk-text-left'])}>
                                  <strong>Hinweis:</strong> Gedichte oder anderweitig geschützte Inhalte sowie externe Links
                                  sind bei Kondolenzen nicht erlaubt und werden deshalb entfernt. Bei Fragen zum Trauerportal
                                  wenden Sie sich bitte an <a href={"mailto:social-media@mgbayern.de"}>social-media@mgbayern.de</a>
                                </div>
                                <textarea
                                    className={[styles['uk-textarea'], styles['uk-padding']].join(' ')}
                                    rows="5"
                                    placeholder="Bitte geben Sie hier Ihren Text ein..."
                                    name="condolenceContent"
                                    onChange={this.handleInputChange}
                                    value={this.state.condolenceTextareaContent}
                                />
                              </>
                          ) : (
                              <>
                                <div className="uk-margin">
                                  <label className="uk-form-label">Name</label>
                                  <div className={styler(['uk-form-controls', 'uk-position-relative'])}>
                                    <input className="uk-input" id="form-horizontal-text" type="text" value={this.state.user_name} onChange={this.handleUserChange} name="user_name" required={true}/>
                                    <div className={styler(['displayed-public', 'uk-text-italic'])}>wird öffentlich angezeigt</div>
                                  </div>
                                </div>
                                <div className={styler(['uk-margin', 'uk-text-left'])}>
                                  <strong>Hinweis:</strong> Gedichte oder anderweitig geschützte Inhalte sowie externe Links
                                  sind bei Kondolenzen nicht erlaubt und werden deshalb entfernt. Bei Fragen zum Trauerportal
                                  wenden Sie sich bitte an <a href={"mailto:social-media@mgbayern.de"}>social-media@mgbayern.de</a>
                                </div>
                                <RichTextEditor
                                    value={this.state.condolenceRichTextContent}
                                    onChange={this.onChange}
                                    toolbarConfig={toolbarConfig}
                                    className={styles.rte}
                                />
                              </>

                          )}
                        </>
                        }
                      </div>
                      {(!this.props.user.hasOwnProperty('id') || this.props.user.id === null) &&
                      <div className={styles['uk-margin']}>
                        <div className={[styles['uk-text-right'], styles['uk-text-italic'], styles['uk-text-small']].join(' ')}>
                          <span>noch {maxCharsCondolence - this.state.condolenceContentLength} Zeichen verbleibend</span>
                        </div>
                      </div>
                      }
                      <div className={styles['uk-margin']}>
                        <input className={styles['uk-hidden']} name="bee" onChange={this.handleBeeChange}/>
                        {!this.state.showLoginForm &&
                        <>
                          {!this.state.validated &&
                          <><div><small>Bitte füllen Sie die Felder Benutzername und Kondolenztext aus</small><br/><br/></div></>
                          }
                          {this.sendButtons()}
                        </>
                        }
                      </div>
                    </form>
                    {this.state.showLoginForm &&
                    <Fade>
                      <LoginForm visible={true} {...this.props} updateForm={this.updateForm}/>
                    </Fade>
                    }
                  </div>
                </Fade>
            )}

        </span>
          )}
          <hr/>
        </div>
    )
  }

}

function

mapStateToProps (state) {
  return {
    user: state.user,
    deathNoticeCondolences: state.deathNoticeCondolences,
  }
}

function

matchDispatchToProps (dispatch) {
  return bindActionCreators({
    getDeathNoticeCondolences: getDeathNoticeCondolences,
  }, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(NewCondolenceForm)
