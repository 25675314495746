import React, {Component} from 'react';
import Api from '../../helpers/api';
import Auth from '../../helpers/auth';
import Fade from '../../helpers/fade';
import Head from '../Layout/Head';
import styles from '../../styles/app.scss';
import IVWPixel from "../../helpers/ivw";
import {initialize} from "../../helpers/agf";

/**
 * Register component
 *
 * @class Register
 * @author Alexander Jungwirth
 */
export default class Register extends Component {
    auth = Auth.state;
    state = {
        user_data: {
            login: '',
            password: '',
            password_accept: '',
            salutation: null,
            firstname: '',
            lastname: '',
            email: '',
            dsgvo: false,
            bee: ''

        },
        login_exists: null,
        email_exists: null,
        email_valid: null,
        loading: false,
        user_id: null,
        password_ok: null,
        password_accept_ok: null,
        data_ok: false,
        success: null,
    };


    componentDidMount() {
        IVWPixel.pageLoad();
        initialize('_register', 'register');
    }

    /**
     * input change handler
     *
     * @param event
     */
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            user_data: {
                ...this.state.user_data,
                [name]: value
            },
        });

    };

    /**
     * register user
     *
     * @param event
     */
    register = (event) => {
        event.preventDefault();
        this.setState({loading: true});

        Api.post('sso/register', {
            customer: this.state.user_data
        }).then((response) => {
            if (response.data.success && response.data.user_id !== null) {
                this.setState({
                    loading: false,
                    success: true,
                });
            } else {
                this.setState({
                    loading: false,
                    success: false,
                });
            }

        })
    };

    /**
     * check if login already exists
     */
    checkLogin = () => {
        if (this.state.user_data.login.length < 3) return;
        Api.postExternal('https://dkd.donaukurier.de/hades2/public/api/auth/search', {
            search: {login: this.state.user_data.login}
        }).then((response) => {
            if (response.data.result === null) {
                this.setState({login_exists: false});
            } else {
                this.setState({login_exists: true});
            }
            this.checkAll();
        })
    };

    /**
     * check if email already exists
     */
    checkEmail = () => {
        if (this.state.user_data.email.length < 3) return;

        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!(re.test(String(this.state.user_data.email).toLowerCase()))) {
            this.setState({
                email_valid: false
            });
            return false;
        } else {
            this.setState({
                email_valid: true
            });
        }

        Api.postExternal('https://dkd.donaukurier.de/hades2/public/api/auth/search', {
            search: {email: this.state.user_data.email}
        }).then((response) => {
            if (response.data.result === null) {
                this.setState({email_exists: false});
            } else {
                this.setState({email_exists: true});
            }
            this.checkAll();
        })
    };

    /**
     * check password (min. 5 chars)
     */
    checkPassword = () => {
        this.setState({
            password_ok: (!(this.state.user_data.password.length < 5))
        });
        this.checkAll();
    };

    /**
     * check password accepted
     */
    checkPasswordAccept = () => {
        this.setState({
            password_accept_ok: (this.state.user_data.password_accept === this.state.user_data.password)
        });
        this.checkAll();

    };

    /**
     * check all input fields
     */
    checkAll = () => {
        console.log('checking');
        this.setState({
            data_ok: (!(
                this.state.email_exists ||
                this.state.login_exists ||
                this.state.user_data.email.length < 1 ||
                this.state.user_data.login.length < 1 ||
                !this.state.password_ok ||
                !this.state.password_accept_ok ||
                this.state.user_data.salutation === null ||
                this.state.user_data.firstname.length < 1 ||
                this.state.user_data.lastname < 1 ||
                !this.state.user_data.dsgvo
            ))
        })
    };

    /**
     * render method
     *
     * @returns {*}
     */
    render() {
        const canonicalUrl = window.location.href;

        return (
            <Fade>
                <Head title="DONAUKURIER Trauerportal - Registrierung" canonicalUrl={canonicalUrl} />
                <div className={[styles["uk-container"], styles["uk-padding"]].join(' ')}>
                    <div className="divider">
                        <h2>Registrierung</h2>
                    </div>


                    {!this.auth.api_token ? (
                        <>
                            {(this.state.success === true) ? (
                                <div>
                                    <p>Vielen Dank für Ihre Registrierung! </p>
                                    <p>Wir haben Ihnen eine Nachricht an die angegebene E-Mail geschickt. Bitte klicken Sie auf den darin enthaltenen Link, um Ihre E-Mail Adresse zu bestätigen.</p>
                                </div>
                            ) : (
                                <form className="uk-form-horizontal uk-margin-small-top">
                                    <div className="uk-margin">
                                        <label className="uk-form-label">gewünschter Benutzername</label>
                                        <div className="uk-form-controls">
                                            <div className="uk-inline uk-display-block uk-position-relative">
                                                {this.state.login_exists !== null &&
                                                <span>
                                            {(!this.state.login_exists) ? (
                                                <span className="uk-form-icon uk-form-icon-flip uk-color-green" data-uk-icon="icon: check"/>
                                            ) : (
                                                <span className="uk-form-icon uk-form-icon-flip uk-color-red" data-uk-icon="icon: close"/>
                                            )}
                                        </span>
                                                }
                                                <input className={"uk-input" + ((this.state.login_exists) ? ' uk-form-danger' : '')}
                                                       type="text"
                                                       placeholder="Benutzername"
                                                       name="login"
                                                       value={this.state.login}
                                                       onChange={this.handleInputChange}
                                                       onBlur={this.checkLogin.bind(this)}
                                                       required
                                                />
                                                {(this.state.login_exists) ? <div className="tooltip">Dieser Benutzername ist bereits vergeben!</div> : null}

                                            </div>
                                        </div>
                                    </div>
                                    <div className="uk-margin">
                                        <label className="uk-form-label">Passwort</label>
                                        <div className="uk-form-controls uk-position-relative">
                                            <input className="uk-input" type="password" placeholder="Passwort" name="password" value={this.state.password} onChange={this.handleInputChange} onBlur={this.checkPassword}
                                                   required/>
                                            {(this.state.password_ok === false) ? <div className="tooltip">Ihr Passwort muss mindestens 5 Zeichen lang sein!</div> : null}
                                        </div>
                                    </div>
                                    <div className="uk-margin">
                                        <label className="uk-form-label">Passwort wiederholen</label>
                                        <div className="uk-form-controls uk-position-relative">
                                            <input className="uk-input" type="password" placeholder="Passwort wiederholen" name="password_accept" value={this.state.password_accept} onChange={this.handleInputChange}
                                                   onBlur={this.checkPasswordAccept} required/>
                                            {(this.state.password_accept_ok === false) ? <div className="tooltip">Die Passwörter stimmen nicht überein!</div> : null}
                                        </div>

                                    </div>
                                    <hr/>
                                    <div className="uk-margin">
                                        <label className="uk-form-label">Anrede</label>
                                        <div className="uk-form-controls">
                                            <select className="uk-select" name="salutation" value={this.state.salutation} onChange={this.handleInputChange} onBlur={this.checkAll} defaultValue="" required>
                                                <option value="" disabled>Bitte wählen...</option>
                                                <option value="Frau">Frau</option>
                                                <option value="Herr">Herr</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="uk-margin">
                                        <label className="uk-form-label">Vorname</label>
                                        <div className="uk-form-controls">
                                            <input className="uk-input" type="text" placeholder="Ihr Vorname" name="firstname" value={this.state.firstname} onChange={this.handleInputChange} onBlur={this.checkAll}
                                                   required/>
                                        </div>
                                    </div>
                                    <div className="uk-margin">
                                        <label className="uk-form-label">Nachname</label>
                                        <div className="uk-form-controls">
                                            <input className="uk-input" type="text" placeholder="Ihr Nachname" name="lastname" value={this.state.lastname} onChange={this.handleInputChange} onBlur={this.checkAll}
                                                   required/>
                                        </div>
                                    </div>
                                    <div className="uk-margin">
                                        <label className="uk-form-label">E-Mail Adresse</label>
                                        <div className="uk-form-controls">
                                            <div className="uk-inline uk-display-block">
                                                {this.state.email_exists !== null &&
                                                <span>
                                            {(!this.state.email_exists) ? (
                                                <span className="uk-form-icon uk-form-icon-flip uk-color-green" data-uk-icon="icon: check"/>
                                            ) : (
                                                <span className="uk-form-icon uk-form-icon-flip uk-color-red" data-uk-icon="icon: close"/>
                                            )}
                                        </span>
                                                }
                                                <input
                                                    className={"uk-input" + ((this.state.email_exists) ? ' uk-form-danger' : '')}
                                                    type="email" placeholder="Ihre E-Mail Adresse"
                                                    name="email" value={this.state.email}
                                                    onChange={this.handleInputChange}
                                                    onBlur={this.checkEmail.bind(this)}
                                                    required
                                                />


                                                {(this.state.email_exists === true) ? <div className="tooltip">Diese E-Mail Adresse ist bereits zugewiesen!</div> : null}
                                                {(this.state.email_valid === false) ? <div className="tooltip">Das ist keine gültige E-Mail Adresse!</div> : null}

                                            </div>
                                        </div>
                                    </div>

                                    <div className="uk-margin">
                                        <div className="uk-form-controls">
                                            <label>
                                                <input className="uk-checkbox uk-margin-small-right" type="checkbox" name="ads" value={this.state.ads} onChange={this.handleInputChange}/>
                                                Ich möchte von der Donaukurier GmbH per E-Mail und Telefon über deren Angebote, Produkte und Dienstleistungen informiert werden und willige ein, dass die Donaukurier GmbH
                                                meine in
                                                diesem
                                                Formular angegebenen personenbezogenen Daten zu diesem Zweck verarbeitet.<br/>
                                                <small>
                                                    Hinweise: Ihre Einwilligung ist freiwillig. Sie haben das Recht, Ihre Einwilligung jederzeit zu widerrufen. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit
                                                    der
                                                    aufgrund
                                                    der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt. Weitere Informationen zur Datenverarbeitung durch uns erhalten Sie in den <a
                                                    href="https://www.donaukurier.de/_/register/agb.html" target="_blank" rel="noreferrer noopener">Datenschutzinformationen</a>.
                                                </small>
                                            </label>
                                        </div>
                                    </div>

                                    <div className="uk-margin">
                                        <div className="uk-form-controls">
                                            <label>
                                                <input className="uk-checkbox uk-margin-small-right" type="checkbox" name="dsgvo" value={this.state.dsgvo} onChange={this.handleInputChange}/>
                                                Mit dieser Registrierung erklären Sie sich mit unseren <a href="https://www.donaukurier.de/_/register/agb.html" target="_blank"
                                                                                                          rel="noreferrer noopener">Datenschutzvereinbarungen</a>
                                                und unserer <a href="https://www.donaukurier.de/interaktiv/mitgliederbereich/regeln/art109275,1905769" target="_blank"
                                                               rel="noreferrer noopener">Netiquette</a> einverstanden.
                                            </label>
                                        </div>
                                    </div>

                                    <input name="bee" type="text" className="uk-hidden" value={this.state.bee} onChange={this.handleInputChange}/>

                                    <div className="uk-margin">
                                        <div className="uk-form-controls">
                                            <button className="uk-button uk-button-primary uk-width-1-6@m" onMouseEnter={this.checkAll} onClick={this.register} disabled={this.state.loading || !this.state.data_ok}>
                                                {(this.state.loading) ? (
                                                    <div data-uk-spinner={"ratio: 0.6"}/>
                                                ) : (
                                                    <span>REGISTRIEREN</span>
                                                )
                                                }
                                            </button>
                                        </div>
                                    </div>

                                </form>
                            )}

                        </>
                    ) : (
                        <div>
                            <p>Hallo {this.auth.salutation} {this.auth.firstname} {this.auth.lastname}, Sie sind bereits registriert!</p>
                        </div>
                    )}

                </div>
            </Fade>
        )
    }
}
