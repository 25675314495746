import React from 'react'
import styles from '../styles/app.scss'
import {Link} from 'react-router-dom'
import moment from 'moment'
import {cdn} from '../helpers/config'

const imgWidth = 360;

class DeathNotice extends React.Component {

    state = {
        rendered: false
    }

    /** prevent multiple rendering */
    shouldComponentUpdate(nextProps, nextState) {
        return false
    }

    render () {

        return <div className={[styles["uk-width-1-1"], styles["uk-width-1-2@s"], styles["uk-width-1-3@m"]].join(' ')}>
            <Link to={'/traueranzeige/' + this.props.notice.vname + '-' + this.props.notice.nname + '/' + this.props.notice.id} title={this.props.notice.vname + ' ' + this.props.notice.nname + ' - Traueranzeige'}>
                <div className={styles["death-notice"]}>
                    <div className={[styles["description"], styles["uk-text-center"], styles["uk-padding-small"]].join(' ')}>
                        <h3 className={styles["name"]}>{this.props.notice.vname} {this.props.notice.nname}</h3>
                        <div className={[styles["date"], styles["uk-color-gold"]].join(' ')}>
                            {moment(this.props.notice.sterbetag).format('L')}
                            {(this.props.notice.wohnort.length > 0) &&
                            <> &middot; {this.props.notice.wohnort}</>
                            }
                        </div>
                    </div>
                    <figure>
                        <img src={cdn + this.props.notice.id + '&width=' + imgWidth}
                             title={this.props.notice.vname + ' ' + this.props.notice.nname + ' - Traueranzeige'}
                             alt={this.props.notice.vname + ' ' + this.props.notice.nname + ' - Traueranzeige'}
                        />
                    </figure>
                </div>
            </Link>
        </div>
    }

}

export default DeathNotice