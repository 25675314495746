import React from 'react'
import {bindActionCreators} from 'redux'
import {getDeathNoticeCondolences} from '../../actions'
import {connect} from 'react-redux'
import Api from '../../helpers/api'
import styles from '../../styles/app.scss'
import Fade from '../../helpers/fade'
import RichTextEditor from 'react-rte'
import styler from '../../helpers/styler'

/** rte toolbar */
const toolbarConfig = {
  display: ['HISTORY_BUTTONS', 'INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'BLOCK_TYPE_DROPDOWN'],
  INLINE_STYLE_BUTTONS: [
    {label: 'fett', style: 'BOLD',},
    {label: 'kursiv', style: 'ITALIC'},
    {label: 'unterstrichen', style: 'UNDERLINE'}
  ],
  BLOCK_TYPE_DROPDOWN: [
    {label: 'normal', style: 'unstyled'},
    {label: 'groß', style: 'header-two'},
    {label: 'mittel', style: 'header-three'}
  ],
  BLOCK_TYPE_BUTTONS: [
    {label: 'Liste', style: 'unordered-list-item'},
    {label: 'Zahlenliste', style: 'ordered-list-item'}
  ]
}

/**
 * Condolence Book Form for user to write a new condolence
 * - if logged in: RichTextEditor, no max chars, edit and delete,
 */
class EditCondolenceForm extends React.Component {

  state = {
    showEntryForm: false,
    condolenceTextareaContent: '',
    condolenceRichTextContent: RichTextEditor.createValueFromString(this.props.condolence.text, 'html'),
    condolenceContentLength: 0,
    showLoginForm: false,
    newCondolenceSent: false
  }

  /**
   * input change handler
   *
   * @param event
   */
  handleInputChange = (event) => {
    const value = event.target.value;
    const length = value.length;

    this.setState({
      condolenceTextareaContent: value,
      condolenceContentLength: length
    })
  }

  onChange = (condolenceRichTextContent) => {
    this.setState({condolenceRichTextContent});
    if (this.props.onChange) {
      // Send the changes up to the parent component as an HTML string.
      // This is here to demonstrate using `.toString()` but in a real app it
      // would be better to avoid generating a string on each change.
      this.props.onChange(
          condolenceRichTextContent.toString('html')
      );
    }
  };

  /**
   * change handler for bee (honeypot)
   *
   * @param event
   */
  handleBeeChange = (event) => {
    const value = event.target.value;

    this.setState({
      bee: value,
    });
  }

  /**
   * show login form
   */
  showLoginForm() {
    this.setState({showLoginForm: true});
    this.forceUpdate();
  }

  /**
   * add condolence
   *
   * @param event
   */
  postMessage(event) {
    event.preventDefault();

    Api.post('update-condolence', {
      condolenceId: this.props.condolence.id,
      userId: this.props.user.id,
      content: this.state.condolenceRichTextContent.toString('html'),
      status: 2
    }).then((response) => {
      if (response.data.success) {
        this.setState({
          newCondolenceSent: true,
          newCondolenceLoading: false,
        }, this.props.callback('Ihr Eintrag wurde geändert. Dieser muss erst von uns freigegeben werden. Vielen Dank für Ihr Verständnis.'))
      } else {
        console.error(response.data.message);
      }
    });

  }

  /**
   * the send button
   *
   * @returns {*}
   */
  sendButtons = () => {
    return (
        <button className={styler(["uk-button", "uk-button-primary", "send-button"])}
                onClick={this.postMessage.bind(this)}
                disabled={this.state.newCondolenceLoading}>
          {(this.state.newCondolenceLoading) ? (
              <Fade>
                <div data-uk-spinner={'ratio:0.8'}/>
              </Fade>
          ) : (
              <span>speichern</span>
          )}
        </button>
    )
  }

  render() {
    return (
        <div>
          <span>
            {(this.state.newCondolenceSent) ? (
                <Fade>
                  <div className={[styles["uk-margin-meduim-top"], styles["uk-margin-medium-bottom"]].join(' ')}>
                    Ihr Eintrag wurde geändert. Dieser muss erst von uns freigegeben werden. Vielen Dank für Ihr Verständnis.
                  </div>
                </Fade>
            ) : (
                <Fade>
                  <div className={[styles["uk-margin-meduim-top"], styles["uk-margin-medium-bottom"]].join(' ')}>
                    <form className={styles["uk-form-horizontal"]}>
                      <div className={[styles["uk-margin-top"], styles["uk-margin-small-bottom"]].join(' ')}>
                        <RichTextEditor
                            value={this.state.condolenceRichTextContent}
                            onChange={this.onChange}
                            toolbarConfig={toolbarConfig}
                            className={styles.rte}
                        />
                      </div>
                      <div className={styles["uk-margin"]}>
                        <input className={styles["uk-hidden"]} name="bee" onChange={this.handleBeeChange}/>
                        {!this.state.showLoginForm &&
                        <>{this.sendButtons()}</>
                        }
                      </div>
                    </form>
                  </div>
                </Fade>
            )}

        </span>
          <hr/>
        </div>
    )
  }

}

function mapStateToProps(state) {
  return {
    user: state.user,
    deathNoticeCondolences: state.deathNoticeCondolences,
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    getDeathNoticeCondolences: getDeathNoticeCondolences,
  }, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(EditCondolenceForm);
