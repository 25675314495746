import React from 'react'
import Fade from '../../helpers/fade'
import Head from '../Layout/Head'
import styles from '../../styles/app.scss'
import history from '../../helpers/history'
import styler from '../../helpers/styler'
import Api from '../../helpers/api'
import {initialize} from "../../helpers/agf";

class SendValidationLink extends React.Component {

    state = {
        loading: true,
        error: null
    }

    componentDidMount () {
        if (!this.props.location.email) {
            history.goBack()
        } else {
            Api.post('sso/send-activation-link', {
                email_hash: this.props.location.email
            }).then((response) => {
                if (response.success) {
                    this.setState({ loading: false })
                } else {
                    this.setState({
                        loading: false,
                        error: response.error
                    })
                }
            })
        }
        initialize('_register', 'register');
    }

    render () {
        const canonicalUrl = window.location.href;

        if (this.props.location.email) {
            return (
                <Fade>
                    <Head title="E-Mail validieren" canonicalUrl={canonicalUrl}/>
                    <div className={[styles['uk-container'], styles['uk-padding']].join(' ')}>
                        <div className="divider">
                            <h2>Registrierung</h2>
                        </div>
                        <div className={styler(['margin-top-20'])}>
                            {!this.state.loading &&
                            <>
                                {(this.state.error) ? (
                                    <p>Beim Senden des Aktivierungslink ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal. Vielen Dank für Ihr Verständnis.</p>
                                ) : (
                                    <p>Wir haben Ihnen eine Nachricht an die angegebene E-Mail geschickt. Bitte klicken Sie auf den darin enthaltenen Link, um Ihre E-Mail Adresse zu bestätigen.</p>
                                )}
                            </>
                            }
                        </div>
                    </div>
                </Fade>
            )
        } else {
            return null
        }
    }

}

export default SendValidationLink