export const getAssetIdFromPath = (pathname) => {
    return pathname
        .replace(/[äÄ]/g, 'ae')
        .replace(/[öÖ]/g, 'oe')
        .replace(/[üÜ]/g, 'ue')
        .replace(/ß/g, 'ss')
        .replace(/[^a-zA-Z0-9-]+/g, '_')
        .replace(/^-+|-+$/g, '');
};

export function initialize(assetId, section) {
    const existingScript = document.getElementById('dynamic-script');
    if (existingScript) {
        const existingMetadata = window.articlemetadata || {};
        if (!(existingMetadata.assetid === assetId && existingMetadata.section === section)) {
            existingScript.remove();
        }
    }

    const scriptContent = `
          var nSdkInstance = NOLBUNDLE.nlsQ("PC96813E9-EFFB-4BA6-9086-F45A12B9FD1A");
    
          window.articlemetadata = {
            type: "static",
            assetid: "${assetId}",
            section: "${section}", 
            segA: "203trauerportal", 
            segB: "CPI",
            segC: "NA",
            subbrand: "c07"
          };
    
          nSdkInstance.ggPM("staticstart", window.articlemetadata);
    
          function bfcacheHandler() {
            window.addEventListener("pageshow", (event) => {
              if (event.persisted) {
                console.log("This page was restored from the bfcache.");
                window.nSdkInstance.ggPM("staticend", previousarticlemetadata);
                window.nSdkInstance.ggPM("staticstart", newarticlemetadata);
              } else {
                console.log("This page was loaded normally.");
              }
            });
          }`;

    const inlineScript = document.createElement('script');
    inlineScript.type = 'text/javascript';
    inlineScript.id = 'dynamic-script';
    inlineScript.innerHTML = scriptContent;
    document.body.appendChild(inlineScript);
}