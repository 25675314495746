import React from 'react'
import { Helmet } from 'react-helmet'
import { client, appName } from '../../helpers/config'

const meta = () => {
    switch (client) {
        case 'dk':
            return {
                title: 'DONAUKURIER Trauerportal',
                description: 'Traueranzeigen und Nachrufe für Ingolstadt, Pfaffenhofen, Schrobenhausen, Neuburg, Eichstätt, Riedenburg, Hilpoltstein, Aichach-Friedberg'
            }

        case 'pnp':
        default:
            return {
                title: 'PNP Trauerportal',
                description: 'Traueranzeigen und Nachrufe für Altötting, Berchtesgadener Land, Deggendorf, Dingolfing-Landau, Freyung-Grafenau, Passau, Regen, Rottal-Inn, Traunstein'
            }


    }
}

const Head = ({ title = meta().title, description = meta().description, canonicalUrl, children }) => {
    return (
        <Helmet encodeSpecialCharacters={true}>
            <meta charSet="utf-8"/>
            <meta name="description" content={description}/>
            <meta property="og:title" content={title === meta().title ? meta().title : title + ' - ' + appName}/>
            <meta property="og:description" content={description}/>
            <meta httpEquiv="cache-control" content="Cache-Control: public, max-age=31536000"/>
            {canonicalUrl && <link rel="canonical" href={canonicalUrl}/>}
            {children && children}
            <title>{title === meta().title ? meta().title : title + ' - ' + appName}</title>
        </Helmet>
    )
}

export default Head
