import React from 'react'
import { client } from '../../helpers/config'

/**
 * show footer tag

 */
const Footer = () => {
    // using variables in import statement does not work yet
    const BrandedFooter = (client === 'pnp') ? React.lazy(() => import('./templateParts/Footer/pnp')) : React.lazy(() => import('./templateParts/Footer/dk'))

    return (
        <React.Suspense fallback={<div>Lade Daten...</div>}>
            <BrandedFooter/>
        </React.Suspense>
    )
}

export default Footer
